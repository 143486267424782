import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Map as CoreMap, useMap } from '@mappr/react-lib';
import { AppContext } from '../../../context';
import { getDomain } from '../../../utils/envUtils';
import { useHistory } from 'react-router-dom';
import { PoiPopUp } from './PopUp/PoiPopUp';
import SecondaryLayerPopUp from '../../../components/Map/SecondaryLayerPopUp/SecondaryLayerPopUp';
import { useParams } from 'react-router';
import { showHideDataLayer } from '../../../utils/urlUtils';
import ModelToggleButton from '../../../components/Map/ModelToggleButton/ModelToggleButton';
import ReactDOM from 'react-dom';
import { useJourneyLines } from '../../../hooks/journeys.ts';

function Map({
    projectConfigs,
    basemaps,
    active,
    journey,
    controlsToggle,
    selectedValues,
    openedControl,
    onToggle,
    centerOnPoint,
}) {
    const history = useHistory();
    const currentBaseMap = sessionStorage.getItem(
        `${getDomain()}-mpr-base-map`
    );
    const { map } = useMap();
    const { page } = useParams();

    const search = new URLSearchParams(window.location.hash.replace('#', ''));
    const { filters } = Object.fromEntries(search.entries());
    const [is3DBtnVisible, setIs3DBtnVisible] = useState(false);
    const [controlContainer, setControlContainer] = useState(null);
    useEffect(() => {
        document
            .getElementById('axeptio_overlay')
            ?.style.removeProperty('z-index');
        if (page === 'map') {
            setTimeout(() => {
                const popup =
                    document.getElementById('MapPopup')?.children.length;
                if (popup) {
                    document.getElementById('axeptio_overlay').style.zIndex =
                        '1';
                }
            }, 200);
        }
    }, [page]);

    useEffect(() => {
        if (map) {
            map.on('zoom', () => {
                if (map.getZoom() >= 15) {
                    setIs3DBtnVisible(true);
                } else {
                    setIs3DBtnVisible(false);
                }
            });
            map.on('load', () => {
                const controlContainer = document.querySelector(
                    '.mapboxgl-control-container'
                );
                setControlContainer(controlContainer);
            });
        }
    }, [map]);

    const [currentBasemapInfo, setCurrentBasemapInfo] = useState();
    const {
        mapCenter,
        mapZoom,
        mapZoomMobile,
        minZoom,
        minZoomMobile,
        maxZoom,
        maxZoomMobile,
        mapPitch,
        mapPitchMobile,
        minPitch,
        minPitchMobile,
        maxPitch,
        maxPitchMobile,
        boundingBox,
        isBboxIncluded,
        models,
        show3dSwitchMobile,
    } = projectConfigs;

    function setupLayerInteractions(layerId) {
        map.on('mouseenter', layerId, (e) => {
            map.getCanvas().style.cursor = 'pointer';
            const coordinates = e.lngLat;
            const imageUrl = e.features[0].properties['picto_final'];

            const popup = document.getElementById('custom-popup');
            const imageElement = document.getElementById('popup-image');
            imageElement.src = imageUrl;

            // Convert coordinates to pixels
            const pixels = map.project(coordinates);
            popup.style.left = pixels.x + 'px';
            popup.style.top = pixels.y - imageElement.offsetHeight + 'px';
            popup.style.display = 'block';
        });

        map.on('mouseleave', layerId, () => {
            setTimeout(() => {
                const popup = document.getElementById('custom-popup');
                popup.style.display = 'none';
                map.getCanvas().style.cursor = '';
            }, 200);
        });
    }

    const handleZoom = () => {
        const layerId = 'SGP_Button';
        const checkLayerExists = () => {
            return map.getLayer(layerId) !== undefined;
        };

        let currentZoom = map.getZoom();
        var visibility = checkLayerExists()
            ? map.getLayoutProperty(layerId, 'visibility')
            : 'none';
        if (openedControl === 'calendar') {
            showHideDataLayer(map, 'visible', true);
            return;
        }

        if (
            Object.keys(selectedValues).includes('lines') &&
            openedControl === ''
        ) {
            if (map.getLayer('feature_text')) {
                const style = map.getStyle();
                for (let i = 0; i < style.layers.length; i++) {
                    if (style.layers[i].id === 'feature_text') {
                        style.layers[i].minzoom = map.getZoom();
                        break;
                    }
                }
                map.setStyle(style);
            }
            showHideDataLayer(map, 'visible');
        } else {
            if (map.getLayer('feature_text')) {
                const style = map.getStyle();
                for (let i = 0; i < style.layers.length; i++) {
                    if (style.layers[i].id === 'feature_text') {
                        style.layers[i].minzoom = 11;
                        break;
                    }
                }
                map.setStyle(style);
            }

            if (currentZoom > 11) {
                showHideDataLayer(map, 'visible');
            } else {
                // This will catch currentZoom <= 11
                if (
                    filters &&
                    JSON.parse(filters).type &&
                    JSON.parse(filters).type.includes('Gare') &&
                    Object.keys(JSON.parse(filters)).length === 1
                ) {
                    showHideDataLayer(map, 'none', true);
                } else if (
                    filters &&
                    JSON.parse(filters).type &&
                    !JSON.parse(filters).type.includes('Gare')
                ) {
                    showHideDataLayer(map, 'visible', false);
                } else if (visibility === 'none') {
                    showHideDataLayer(map, 'none');
                }
            }
        }
    };

    useEffect(() => {
        if (currentBaseMap) {
            const basemapInfo = basemaps.filter(
                (basemap) => basemap.name === currentBaseMap
            );
            setCurrentBasemapInfo(basemapInfo[0]);
        }
    }, [basemaps, currentBaseMap]);

    useEffect(() => {
        if (map) {
            map.on('load', handleZoom);
            setupLayerInteractions('metroLayer');
            setupLayerInteractions('trainLayer');
            setupLayerInteractions('rerLayer');
        }
    }, [map]);

    useEffect(() => {
        if (map) {
            map.on('zoom', handleZoom);

            return () => {
                map.off('zoom', handleZoom);
            };
        }
    }, [map, filters, openedControl]);

    let handleZoomEnd = () => {
        let currentZoom = map.getZoom();
        if (filters && JSON.parse(filters).type) {
            if (
                currentZoom <= 11 &&
                JSON.parse(filters).type.includes('Gare') &&
                JSON.parse(filters).type.length > 1
            ) {
                onToggle('type', 'Gare');
            } else if (
                currentZoom > 11 &&
                !JSON.parse(filters).type.includes('Gare')
            ) {
                onToggle('type', 'Gare');
            }
        }
    };

    useEffect(() => {
        if (map) {
            map.on('zoomend', handleZoomEnd);
            return () => map.off('zoomend', handleZoomEnd);
        }
    }, [map, filters]);

    useEffect(() => {
        if (map) {
            map.on('click', 'SGP_Button', (e) => {
                const lineName = e.features[0].properties['title'].toString();
                const search = new URLSearchParams(
                    window.location.hash.replace('#', '')
                );
                const { filters } = Object.fromEntries(search.entries());
                if (
                    !filters ||
                    !JSON.parse(filters).type.includes('Gare') ||
                    Object.keys(JSON.parse(filters)).length === 0
                ) {
                    onToggle('type', 'Gare');
                }
                // map.zoomTo(11.1);
                centerOnPoint(map, lineName);
                onToggle('lines', lineName);
                showHideDataLayer(map, 'visible');
                map.setLayoutProperty('SGP_Button', 'visibility', 'none');
            });
        }
    }, [map]);

    const openStreetViewModal = (feature, methods) => {
        let obj = feature.properties;
        methods.setMedias([{ embedUrl: obj.URL }]);

        return false;
    };

    useJourneyLines(map);

    return (
        <div
            className={`container-fluid h-100 w-100  px-0 top-0 position-absolute ${
                !active ? 'page-hidden' : 'page-active'
            }`}
            onClick={() => {
                controlsToggle('');
            }}
        >
            <div className="w-100 h-100 d-flex flex-column justify-content-start align-items-stretch align-content-stretch flex-nowrap">
                <AppContext.Consumer>
                    {({ streetViewModal }) => {
                        return (
                            currentBasemapInfo && (
                                <CoreMap
                                    lng={
                                        mapCenter.longitude ||
                                        mapCenter.longitude
                                    }
                                    lat={
                                        mapCenter.latitude || mapCenter.latitude
                                    }
                                    zoom={mapZoomMobile || mapZoom}
                                    url={
                                        currentBasemapInfo &&
                                        currentBasemapInfo.url
                                    }
                                    models={models}
                                    openStreetViewModal={(feature) =>
                                        openStreetViewModal(feature, {
                                            setMedias:
                                                streetViewModal.setMedias,
                                        })
                                    }
                                    SecondaryLayerPopUpComponent={
                                        SecondaryLayerPopUp
                                    }
                                    isMobile={true}
                                    PopUpComponent={PoiPopUp}
                                    history={history}
                                    minZoom={minZoomMobile || minZoom}
                                    maxZoom={maxZoomMobile || maxZoom}
                                    pitch={mapPitchMobile || mapPitch || 45}
                                    minPitch={minPitchMobile || minPitch || 0}
                                    maxPitch={maxPitchMobile || maxPitch || 60}
                                    boundingBox={boundingBox}
                                    isBboxIncluded={isBboxIncluded !== false}
                                />
                            )
                        );
                    }}
                </AppContext.Consumer>
                {show3dSwitchMobile &&
                    is3DBtnVisible &&
                    controlContainer &&
                    ReactDOM.createPortal(
                        <div className="mapbox-control-bottom-left">
                            <ModelToggleButton />
                        </div>,
                        controlContainer
                    )}
                {!journey && (
                    <div
                        style={{ bottom: '0', width: '100vw' }}
                        id={'mobilePopupContainer'}
                    ></div>
                )}
            </div>
        </div>
    );
}

Map.propTypes = {
    position: PropTypes.oneOf(['left', 'right', 'full']).isRequired,
    loading: PropTypes.bool,
    projectConfigs: PropTypes.object,
    basemaps: PropTypes.array,
    journey: PropTypes.string,
    openedControl: PropTypes.string,
    active: PropTypes.bool,
    handleMobilePage: PropTypes.func,
    centerOnPoint: PropTypes.func,
    controlsToggle: PropTypes.func,
    onToggle: PropTypes.func,
    selectedValues: PropTypes.object,
};

Map.defaultProps = {
    position: 'left',
    loading: true,
};

export default Map;
