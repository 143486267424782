import React from 'react';
import Header from '../../Header/Header';
import ListLoader from '../../../../components/loaders/ListLoader/ListLoader';
import { Journey } from '../../../../components/Journey/Journey';
import PropTypes from 'prop-types';
import NotFound from '../../../../components/NotFound/NotFound';
import { useJourneyHash, useJourneys } from '../../../../hooks/journeys.ts';

export const JourneyList = ({ project }) => {
    const { data: journeys, isPending, isError } = useJourneys();
    const { clear: clearJourneyHash } = useJourneyHash();

    if (isPending) {
        return <ListLoader />;
    }
    if (isError) {
        return <NotFound />;
    }

    return (
        <>
            <Header
                projectConfigs={project.configs}
                deselect={() => {
                    clearJourneyHash();
                }}
            />

            <Journey journeys={journeys} />
        </>
    );
};
JourneyList.propTypes = {
    project: PropTypes.object,
};
