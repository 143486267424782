import { useLocation } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useCallback, useEffect, useState } from 'react';
import { stringify as qsStringify } from 'qs';

type JourneySearchParams = {
    from: string | null;
    to: string | null;
};

/**
 * Hook that allows to manipulate the journey parameter in URL hash.
 *
 * @returns
 */
export const useJourneyHash = (): ({
    value: JourneySearchParams,
    setValue: (from: string, to: string) => void,
    clear: () => void,
}) => {
    const { hash } = useLocation();
    let value: JourneySearchParams = {
        from: null,
        to: null,
    };

    const params = new URLSearchParams(hash);
    const journeyHash = params.get('journey');
    if (journeyHash) {
        const journeyParams = new URLSearchParams(JSON.parse(journeyHash));
        value = {
            from: journeyParams.get('from'),
            to: journeyParams.get('to'),
        };
    }

    const setValue = useCallback(
        (from: string, to: string) =>
            setHash('journey', `from=${from}&to=${to}`),
        []
    );
    const clear = useCallback(() => setHash('journey', ''), []);

    return {
        value,
        setValue,
        clear,
    };
};

/**
 * Tanstack query hook that returns the journeys matching the journey parameter in URL hash.
 *
 * @returns
 */
export const useJourneys = () => {
    const { value } = useJourneyHash();
    const { from, to } = value;
    return useQuery({
        queryKey: ['journeys', { from, to }],
        queryFn: () => getJourneys(from, to),
    });
};

const setHash = (key, value) => {
    const hash = window.location.hash.replace('#', '');
    const params = new URLSearchParams(hash);

    if (!value) {
        params.delete(key);
    } else {
        params.set(key, JSON.stringify(value));
    }

    window.location.hash = params.toString();
};

export const setJourneyHash = (from: string, to) => {
    setHash('journey', `from=${from}&to=${to}`);
};

type NavitiaContext = {
    timezone: string;
    current_datetime: number;
    car_direct_path: {
        co2_emission: {
            value: number;
            unit: string;
        }
    };
}

type JourneySection = {
    type: 'street_network' | 'transfer';
    geojson: {
        coordinates: number[];
    };
}

type NavitiaJourney = {
    type: 'best' | 'rapid' | 'comfort' | 'car' | 'less_fallback_walk' | 'less_fallback_bike' | 'less_fallback_bss' | 'fastest' | 'ecologic' | 'reliable' | 'bike_in_pt' | 'non_pt_walk' | 'non_pt_bike' | 'non_pt_bss';
    duration: number;
    nb_transfers: number;
    co2_emission: {
        value: number;
        unit: string;
    };
    sections: JourneySection[],
}

type Journey = NavitiaJourney & {
    name: string;
    context: NavitiaContext;
    journeyCoordinates: number[];
}

export async function getJourneySuggestions(text: string) {
    if (text?.length > 1) {
        try {
            const response = await fetch(
                `https://api.navitia.io/v1/coverage/grandparis-sgp/places?q=${text}&type[]=address&type[]=stop_area`,
                {
                    headers: {
                        Authorization:
                            'Basic ' +
                            btoa(process.env.REACT_APP_NAVITIA_TOKEN),
                    },
                }
            );

            if (response.status === 200) {
                const data = await response.json();
                return data.places.map((item) => ({
                    ...item,
                    coord: item[item.embedded_type].coord,
                }));
            }
        } catch (error) {}
    }

    return null;
}

async function getJourneys(from: string | null, to: string | null): Promise<Journey[]> {
    if (!from || !to) {
        return [];
    }

    const coverage = 'grandparis-sgp';
    const datetime =  '20310205T080000';

    const headers = {
        Authorization: 'Basic ' + btoa(process.env.REACT_APP_NAVITIA_TOKEN),
    };
    const url = `https://api.navitia.io/v1/coverage/${coverage}/journeys`;

    const deadlines = [
        {
            name: 'now',
            params: {
                forbidden_uris: [
                    'network:sgp_mi_2024:2',
                    'network:sgp_mi_2026:2',
                    'network:sgp_fi_2026:2',
                    'network:sgp_fi_2027:2',
                    'network:sgp_fi_2028:2',
                    'network:sgp_fi_2030:2',
                    'network:sgp_2031:2',
                ],
            }
        },
        {
            name: '2031',
            params: {
                forbidden_uris: [
                    'network:sgp_mi_2024:2',
                    'network:sgp_mi_2026:2',
                    'network:sgp_fi_2026:2',
                    'network:sgp_fi_2027:2',
                    'network:sgp_fi_2028:2',
                    'network:sgp_fi_2030:2',
                ],
            }
        },
        
    ];

    const allJourneys = await Promise.all(
        deadlines.map(async ({ name, params }) => {
            const response = await fetch(`${url}?${
                qsStringify({
                    from,
                    to,
                    datetime,
                    ...params
                }, { arrayFormat: 'brackets' })
            }`, { headers });
            const { context, journeys } = await response.json() as { context: NavitiaContext; journeys: Journey[]; };

            let bestJourney: Journey | null = null;
            for (let i = 0; i < journeys.length; i++) {
                if (journeys[i].type === 'fastest') {
                    bestJourney = journeys[i];
                    break;
                } else if (journeys[i].type === 'best') {
                    bestJourney = journeys[i];
                }
            }

            const journeyCoordinates = bestJourney?.sections
                .filter((section) => section?.geojson)
                .map((coord) => coord?.geojson.coordinates)
                .flat();

            return {
                name,
                context,
                ...bestJourney as NavitiaJourney,
                journeyCoordinates,
            };
        })
    );

    const { improvingJourneys } = allJourneys.reduce(
        ({ previousDuration, improvingJourneys }, journey: Journey) => {
            if (journey.duration < previousDuration) {
                improvingJourneys.push(journey);
                return {
                    previousDuration: journey.duration,
                    improvingJourneys,
                };
            }

            return {
                previousDuration,
                improvingJourneys,
            };
        },
        {
            previousDuration: Number.POSITIVE_INFINITY,
            improvingJourneys: [] as Journey[],
        }
    );

    return improvingJourneys;
}

const addJourneysLayer = (map) => {
    map.addSource('journeyLines', {
        type: 'geojson',
        data: null,
    });

    map.addLayer({
        id: 'journey-lines',
        type: 'line',
        source: 'journeyLines',
        paint: {
            'line-color': ['get', 'color'],
            'line-width': ['get', 'width'],
        },
        layout: {
            'line-cap': 'round',
            'line-join': 'round',
        },
        filter: ['==', '$type', 'LineString']
    });

    map.addLayer({
        id: 'journey-points',
        type: 'circle',
        source: 'journeyLines',
        paint: {
            'circle-color': '#039DD1',
            'circle-radius': 10,
            'circle-stroke-color': '#0C208F',
            'circle-stroke-width': 4,
        },
        filter: ['==', '$type', 'Point']
    });
};

export const useJourneyLines = (map) => {
    const [mapReady, setMapReady] = useState<boolean>(false);
    const { data: journeys } = useJourneys();

    useEffect(() => {
        if (map) {
            const initialize = () => {
                addJourneysLayer(map);
                setMapReady(true);
            };
            map.on('load', initialize);

            return () => {
                map.off('load', initialize);
            };
        }
    }, [map]);

    useEffect(() => {
        if (map && mapReady) {
            const lineSource = map.getSource('journeyLines');
            if (!journeys || journeys.length === 0) {
                lineSource.setData({
                    type: 'FeatureCollection',
                    features: []
                });
                return;
            }

            const linesFeatures = [
                {
                    type: 'Feature',
                    geometry: {
                        type: 'LineString',
                        coordinates: journeys[0].journeyCoordinates,
                    },
                    properties: {
                        name: 'currentJourneyLine',
                        width: 9,
                        color: '#E84261',
                    },
                },
            ];

            const bestJourneyCoordinates = journeys[journeys.length - 1].journeyCoordinates;

            if (journeys.length > 1) {
                linesFeatures.push({
                    type: 'Feature',
                    geometry: {
                        type: 'LineString',
                        coordinates:
                            journeys[journeys.length - 1].journeyCoordinates,
                    },
                    properties: {
                        name: 'withGrandPariGeojsonCoordinates',
                        width: 6,
                        color: '#039DD1',
                    },
                });
            }

            lineSource.setData({
                type: 'FeatureCollection',
                features: [
                    ...linesFeatures,
                    {
                        type: 'Feature',
                        geometry: {
                            type: 'Point',
                            coordinates: bestJourneyCoordinates[0],
                        },
                        properties: {
                            name: 'start',
                        },
                    },
                    {
                        type: 'Feature',
                        geometry: {
                            type: 'Point',
                            coordinates: bestJourneyCoordinates[bestJourneyCoordinates.length - 1],
                        },
                        properties: {
                            name: 'finish',
                        },
                    },
                ],
            });
        }
    }, [map, mapReady, journeys]);
}