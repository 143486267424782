import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { getDomain } from './utils/envUtils';
import { useParams } from 'react-router-dom';
import { Mappr } from '@mappr/react-lib';
import OldBrowserMessage from './components/OldBrowserMessage/OldBrowserMessage';
import { Redirect, withRouter } from 'react-router';
import FullPageLoading from './components/loaders/FullPageLoading/FullPageLoading';
import DetectBrowser from 'react-detect-browser';
import { reducer } from './App.store';
import { Alert } from 'react-bootstrap';
import MainContainer from './components/MainContainer/MainContainer';
import './App.scss';
import { AppContext, MapContext } from './context';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
});

// eslint-disable-next-line react/prop-types
function App({ match }) {
    const [domain, setDomain] = useState();
    const { lang } = useParams();
    let setStreetViewMedias;
    const activeBaseMap = sessionStorage.getItem(`${getDomain()}-mpr-base-map`);
    const activeLayout = sessionStorage.getItem(`${getDomain()}-mpr-layout`);
    const customizeApp = (theme, layout, baseMap) => {
        const d = getDomain();
        if (activeBaseMap !== baseMap) {
            sessionStorage.setItem(`${d}-mpr-base-map`, baseMap);
        }
    };

    const mapState = {
        feature: {
            hovered: {
                item: null,
                popupElement: null,
                methods: {},
            },
            selected: {
                item: null,
                popupElement: null,
                methods: {},
            },
        },
    };
    const [appState, setAppState] = useReducer(reducer, {
        domain: '',
        baseMap: activeBaseMap || 'base-map-2',
        mprApiUrl: process.env.REACT_APP_MPR_API_URL,
        mprGqlUrl: process.env.REACT_APP_MPR_GQL_URL,
        mprTileUrl: process.env.REACT_APP_MPR_TILE_URL,
        projectConfigs: null,
        mobilePage: 'map',
        layout: activeLayout || 'sidebarLeft',
        activeSecondaryLayers: [],
        toggleLayer: null,
        locale: {
            value: lang,
        },

        streetViewModal: {
            open: false,
            medias: [],
            type: 'street_view',
            handleClose: () => toggleModal('streetViewModal', false),
            setMedias: setStreetViewMedias,
        },
        configModal: {
            handleSave: customizeApp,
        },
        isSecondaryLayer: false,
    });

    setStreetViewMedias = useCallback(
        (medias) => {
            setAppState({
                prop: 'streetViewModal',
                value: {
                    ...appState.streetViewModal,
                    medias: medias,
                    open: true,
                },
            });
        },
        [appState.streetViewModal]
    );

    useEffect(() => {
        setAppState({ prop: 'locale', value: lang });
    }, [match]);

    useEffect(() => {
        const d = getDomain();
        setDomain(d);
        setAppState({ prop: 'domain', value: d });

        document.addEventListener('select-pegman', (event) => {
            setStreetViewMedias([{ embedUrl: event.detail.url }]);
        });

        return () => {
            document.removeEventListener('select-pegman', (event) => {
                setStreetViewMedias([{ embedUrl: event.detail.url }]);
            });
        };
    }, [setStreetViewMedias]);

    const toggleModal = useCallback(
        (modal, open) => {
            setAppState({
                prop: modal,
                value: {
                    ...appState[modal],
                    open: open,
                },
            });
        },
        [appState]
    );

    return (
        <QueryClientProvider client={queryClient}>
            <DetectBrowser>
                {({ browser }) =>
                    (browser.os === 'iOS' &&
                        parseFloat(browser.version) <= 10) ||
                    browser.name === 'ie' ||
                    (browser.name === 'chrome' &&
                        parseFloat(browser.version) <= 58) ||
                    (browser.name === 'firefox' &&
                        parseFloat(browser.version) <= 46) ? (
                        <OldBrowserMessage />
                    ) : (
                        <AppContext.Provider
                            value={{ ...appState, setAppState }}
                        >
                            <MapContext.Provider value={mapState}>
                                <div className="App">
                                    {domain && (
                                        <Mappr
                                            name={domain}
                                            mprApiUrl={appState.mprApiUrl}
                                            mprGqlUrl={appState.mprGqlUrl}
                                            mprTileUrl={appState.mprTileUrl}
                                            apiUrl={
                                                process.env
                                                    .REACT_APP_MPR_GQL_URL
                                            }
                                            language={
                                                appState.locale.value || 'en'
                                            }
                                        >
                                            {({
                                                loading,
                                                error,
                                                project,
                                                basemaps,
                                            }) => {
                                                if (loading) {
                                                    return <FullPageLoading />;
                                                }
                                                if (
                                                    project &&
                                                    ((!lang &&
                                                        project.configs
                                                            .defaultLocale) ||
                                                        (lang &&
                                                            !project.configs.locales.find(
                                                                (_) =>
                                                                    _.value ===
                                                                    lang
                                                            )))
                                                ) {
                                                    return (
                                                        <Redirect
                                                            exact={true}
                                                            to={`/${project.configs.defaultLocale.value}/line/`}
                                                        />
                                                    );
                                                }

                                                if (error) {
                                                    return (
                                                        <Alert
                                                            variant="danger"
                                                            dismissible={false}
                                                        >
                                                            {error}
                                                        </Alert>
                                                    );
                                                }
                                                return (
                                                    <MainContainer
                                                        setAppState={
                                                            setAppState
                                                        }
                                                        project={project}
                                                        basemaps={basemaps}
                                                        domain={domain}
                                                        loading={loading}
                                                    />
                                                );
                                            }}
                                        </Mappr>
                                    )}
                                </div>
                            </MapContext.Provider>
                        </AppContext.Provider>
                    )
                }
            </DetectBrowser>
        </QueryClientProvider>
    );
}

export default withRouter(App);
