import React, { useCallback, useContext, useEffect } from 'react';
import { setHash } from '../../utils/urlUtils';
import { AppContext } from '../../context';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useMap } from '@mappr/react-lib';
/* eslint-disable */

export const CalendarItems = ({
    openedControls,
    toggleLayer,
    layers,
    activeLayers,
    onToggle,
    selectedValues,
    closeCalendar,
    controlsToggle,
}) => {
    const { configModal } = useContext(AppContext);
    const { t } = useTranslation();
    const { map } = useMap();
    useEffect(() => {
        toggleLayer('background-111', 'basemap');
    }, [openedControls]);

    // const onChangeValue = (e) => {
    //     if (e.target.value === 'background-111'){
    //         location.href = '/';
    //     }
    //     // console.log(e.target.value)
    //     toggleLayer(e.target.value, 'basemap');
    //     // if (map) {
    //     //     let currentZoom = map.getZoom();
    //     //     console.log(44333)
    //     //     map.zoomTo(currentZoom - 0.1);
    //     // }
    // };

    const onChangeValue = (e) => {
        const center = map.getCenter();
        let zoom = map.getZoom();

        map.scrollZoom.disable();
        map.doubleClickZoom.disable();
        map.touchZoomRotate.disable();
        map.boxZoom.disable();
        map.keyboard.disable();

        if (e.target.value === 'background-111') {
            location.href = '/';
        }

        toggleLayer(e.target.value, 'basemap');
        map.setCenter(center);
        map.once('moveend', () => {
            map.setCenter(center);
        });
        setTimeout(() => {
            if (zoom > 9.9) {
                map.setZoom(9.9);
            } else {
                map.setZoom(zoom);
            }

            map.scrollZoom.enable();
            map.doubleClickZoom.enable();
            map.touchZoomRotate.enable();
            map.boxZoom.enable();
            map.keyboard.enable();
        }, 100);
    };

    const handleCalendarToggle = useCallback(
        (selectedValues, onToggle, basemap) => {
            if (
                selectedValues?.calendar &&
                selectedValues?.calendar.length > 0
            ) {
                onToggle('calendar', selectedValues?.calendar[0]);
            }
            onToggle('calendar', basemap.label.split('_')[1]);
            if (basemap.label.split('_')[1] === 'Full') {
                onToggle('calendar', 'Full');
            }
            setHash('basemap', basemap.name);
            controlsToggle('calendar');
            // configModal.handleSave(basemap.name);
        },
        [configModal]
    );
    return (
        <>
            {openedControls === 'calendar' && (
                <div className="calendar max-z-index">
                    <p className="mb-2">{t('calendar.menuTitle')}</p>

                    <ul
                        id="date-slider"
                        className="d-flex w-100 p-0 mb-1 align-items-end"
                    >
                        {!!layers &&
                            layers.map((basemap) => (
                                <label
                                    key={basemap.name}
                                    className={`d-flex flex-column align-items-center ${
                                        +basemap.label.replace('SGP_', '') <
                                            +activeLayers.replace(
                                                'background-',
                                                ''
                                            ) ||
                                        (activeLayers.includes('111') &&
                                            !basemap.label.includes('Full'))
                                            ? 'active'
                                            : ''
                                    }`}
                                    htmlFor={basemap.label}
                                    data-date={t(
                                        `project:basemap.${basemap.label}`
                                    )}
                                >
                                    <span className="date-name text-capitalize text-center px-2 mb-1">
                                        {t(`project:basemap.${basemap.label}`)}
                                    </span>
                                    <input
                                        checked={activeLayers === basemap.name}
                                        type="radio"
                                        name="date"
                                        id={basemap.label}
                                        value={basemap.name}
                                        onChange={onChangeValue}
                                        onClick={() => {
                                            handleCalendarToggle(
                                                selectedValues,
                                                onToggle,
                                                basemap
                                            );
                                        }}
                                        required
                                    />
                                </label>
                            ))}
                    </ul>
                    <div
                        className={'calendar-close-btn'}
                        onClick={closeCalendar}
                    >
                        <div className="calendar-close" />
                    </div>
                </div>
            )}
        </>
    );
};
CalendarItems.propTypes = {
    openedControls: PropTypes.string,
    controlsToggle: PropTypes.func,
    toggleLayer: PropTypes.func,
    onToggle: PropTypes.func,
    closeCalendar: PropTypes.func,
    layers: PropTypes.array,
    activeLayers: PropTypes.string,
    selectedValues: PropTypes.object,
};
