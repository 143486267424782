import React, {
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';
import PropTypes from 'prop-types';
import { Map as CoreMap, use3DLayer, useMap } from '@mappr/react-lib';
import { AppContext } from '../../../context';
import './Map.scss';
import Search from '../../../components/Search/Search';
import Calendar from '../../../components/Calendar/Calendar';
import { getDomain } from '../../../utils/envUtils';
import StreetViewModal from '../../../components/modals/StreetViewModal/StreetViewModal';
import SecondaryLayerPopUp from '../../../components/Map/SecondaryLayerPopUp/SecondaryLayerPopUp';
import { PoiPopUp } from '../../../components/Map/PoiPopUp/PoiPopUp';
import { showHideDataLayer } from '../../../utils/urlUtils';
import LayersMenu from '../../../components/Map/LayersMenu/LayersMenu';
import mapboxgl from 'mapbox-gl';
import { Form } from 'react-bootstrap';
import { useJourneyLines } from '../../../hooks/journeys.ts';

function Map({
    setOpenedControl,
    projectConfigs,
    basemaps,
    isSidebarOpen,
    openedControl,
    setSideBarOpen,
    sidebarRef,
    onToggle,
    selectedValues,
    removeAllFilters,
    centerOnPoint,
}) {
    const currentBaseMap = sessionStorage.getItem(
        `${getDomain()}-mpr-base-map`
    );
    const { map } = useMap();
    const { layout, streetViewModal } = useContext(AppContext);

    const [maxZoomBackup, setMaxZoomBackup] = useState();
    const [currentBasemapInfo, setCurrentBasemapInfo] = useState();
    // const [is3DBtnVisible, setIs3DBtnVisible] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [mapReady, setMapReady] = useState(false);
    const dropdownRef = useRef();
    const { visible, toggleVisibility } = use3DLayer();
    const search = new URLSearchParams(window.location.hash.replace('#', ''));
    const { filters } = Object.fromEntries(search.entries());

    let popup = useRef();

    const {
        mapCenter,
        mapZoom,
        minZoom,
        maxZoom,
        mapPitch,
        minPitch,
        maxPitch,
        show3dSwitch,
    } = projectConfigs;

    const controlsToggle = useCallback(
        (name) => {
            setOpenedControl(name);
        },
        [setOpenedControl]
    );
    // useEffect(() => {
    //     if (map) {
    //         map.on('zoom', () => {
    //             if (map.getZoom() >= 15) {
    //                 setIs3DBtnVisible(true);
    //             } else {
    //                 setIs3DBtnVisible(false);
    //             }
    //         });
    //     }
    // }, [map]);

    useEffect(() => {
        popup.current = new mapboxgl.Popup({
            closeButton: false,
            closeOnClick: false,
        });
    }, []);

    const handleMouseEnter = useCallback(
        (layerId) => {
            map.on('mouseenter', layerId, (e) => {
                map.getCanvas().style.cursor = 'pointer';
                const coordinates = e.lngLat;
                const imageUrl = e.features[0].properties['picto_final'];
                if (imageUrl) {
                    const content = `<img src="${imageUrl}" alt="Image" width="50"/>`;
                    popup.current
                        .setLngLat(coordinates)
                        .setHTML(content)
                        .addTo(map);
                } else {
                    console.warn(`No image URL found for ${layerId}`);
                }
            });
        },
        [map, popup]
    );

    const handleMouseLeave = useCallback(
        (layerId) => {
            map.on('mouseleave', layerId, () => {
                map.getCanvas().style.cursor = '';
                popup.current.remove();
            });
        },
        [map, popup]
    );

    const handleZoom = () => {
        const layerId = 'SGP_Button';
        const checkLayerExists = () => {
            return map.getLayer(layerId) !== undefined;
        };

        let currentZoom = map.getZoom();
        var visibility = checkLayerExists()
            ? map.getLayoutProperty(layerId, 'visibility')
            : 'none';
        if (openedControl === 'calendar') {
            showHideDataLayer(map, 'visible', true);
            return;
        }

        if (
            Object.keys(selectedValues).includes('lines') &&
            openedControl === ''
        ) {
            if (map.getLayer('feature_text')) {
                const style = map.getStyle();
                for (let i = 0; i < style.layers.length; i++) {
                    if (style.layers[i].id === 'feature_text') {
                        style.layers[i].minzoom = map.getZoom();
                        break;
                    }
                }
                map.setStyle(style);
            }
            showHideDataLayer(map, 'visible');
        } else {
            if (map.getLayer('feature_text')) {
                const style = map.getStyle();
                for (let i = 0; i < style.layers.length; i++) {
                    if (style.layers[i].id === 'feature_text') {
                        style.layers[i].minzoom = 11;
                        break;
                    }
                }
                map.setStyle(style);
            }

            if (currentZoom > 11) {
                showHideDataLayer(map, 'visible');
            } else {
                // This will catch currentZoom <= 11
                if (
                    filters &&
                    JSON.parse(filters).type &&
                    JSON.parse(filters).type.includes('Gare') &&
                    Object.keys(JSON.parse(filters)).length === 1
                ) {
                    showHideDataLayer(map, 'none', true);
                } else if (
                    filters &&
                    JSON.parse(filters).type &&
                    !JSON.parse(filters).type.includes('Gare')
                ) {
                    showHideDataLayer(map, 'visible', false);
                } else if (visibility === 'none') {
                    showHideDataLayer(map, 'none');
                }
            }
        }
    };

    useEffect(() => {
        if (currentBaseMap) {
            const basemapInfo = basemaps.filter(
                (basemap) => basemap.name === currentBaseMap
            );
            setCurrentBasemapInfo(basemapInfo[0]);
        }
    }, [isSidebarOpen, currentBaseMap, basemaps]);

    useEffect(() => {
        if (map && projectConfigs?.sidebarCollapse) {
            const padding = ['sidebarRight', 'headerRight'].includes(layout)
                ? {
                      right: isSidebarOpen
                          ? sidebarRef?.current?.offsetWidth
                          : 0,
                  }
                : {
                      left: isSidebarOpen
                          ? sidebarRef?.current?.offsetWidth
                          : 0,
                  };
            // window.sidebarOffsetX = padding.left/2;
            setTimeout(() => {
                map.easeTo({
                    padding: padding,
                    duration: 500,
                });
            }, 50);
        }
    }, [
        isSidebarOpen,
        sidebarRef,
        map,
        projectConfigs?.sidebarCollapse,
        layout,
    ]);

    useEffect(() => {
        if (map) {
            map.on('load', handleZoom);
            handleMouseEnter('metroLayer');
            handleMouseEnter('trainLayer');
            handleMouseEnter('rerLayer');

            handleMouseLeave('metroLayer');
            handleMouseLeave('trainLayer');
            handleMouseLeave('rerLayer');
        }
    }, [map]);

    useEffect(() => {
        if (map) {
            if (selectedValues && selectedValues.calendar) {
                handleZoom();
            } else {
                map.on('zoom', handleZoom);
            }

            return () => {
                if (selectedValues && selectedValues.calendar) {
                    handleZoom();
                } else {
                    map.off('zoom', handleZoom);
                }
            };
        }
    }, [map, selectedValues]);

    let handleZoomEnd = () => {
        let currentZoom = map.getZoom();
        if (filters && JSON.parse(filters).type) {
            if (
                currentZoom <= 11 &&
                JSON.parse(filters).type.includes('Gare') &&
                JSON.parse(filters).type.length > 1
            ) {
                onToggle('type', 'Gare');
            } else if (
                currentZoom > 11 &&
                !JSON.parse(filters).type.includes('Gare')
            ) {
                onToggle('type', 'Gare');
            }
        }
    };

    useEffect(() => {
        if (map) {
            map.on('zoomend', handleZoomEnd);
            return () => map.off('zoomend', handleZoomEnd);
        }
    }, [map, filters]);

    useEffect(() => {
        if (map) {
            map.on('click', 'SGP_Button', (e) => {
                const lineName = e.features[0].properties['title'].toString();
                const search = new URLSearchParams(
                    window.location.hash.replace('#', '')
                );
                const { filters } = Object.fromEntries(search.entries());
                if (
                    !filters ||
                    !JSON.parse(filters).type.includes('Gare') ||
                    Object.keys(JSON.parse(filters)).length === 0
                ) {
                    onToggle('type', 'Gare');
                }
                // map.zoomTo(11.1);
                centerOnPoint(map, lineName);
                onToggle('lines', lineName);
                showHideDataLayer(map, 'visible');
                map.setLayoutProperty('SGP_Button', 'visibility', 'none');
            });
        }
    }, [map]);

    // useEffect(() => {
    //     function handleClickOutside(event) {
    //         const header = document.querySelector('#header');
    //         if (
    //             dropdownRef.current &&
    //             !dropdownRef.current.contains(event.target) &&
    //             !header.contains(event.target)
    //         ) {
    //             setOpenedControl('');
    //             controlsToggle('');
    //         }
    //     }
    //
    //     document.addEventListener('mousedown', handleClickOutside);
    //     return () => {
    //         document.removeEventListener('mousedown', handleClickOutside);
    //     };
    // }, [controlsToggle, dropdownRef, setOpenedControl]);

    const openStreetViewModal = (feature, methods) => {
        let obj = feature.properties;
        methods.setMedias([{ embedUrl: obj.URL }]);

        return false;
    };
    const clearCustomLayers = (activeLayers, toggleLayer) => {
        if (map && activeLayers) {
            map.getStyle().layers.forEach((layer) => {
                if (activeLayers.includes(layer.id)) {
                    // Custom layer identification
                    toggleLayer(layer.id, 'secondary');
                }
            });
        }
    };

    useJourneyLines(map);

    return (
        <>
            <div
                id="map-container"
                className={`bg-grey p-0 ${
                    isSidebarOpen
                        ? 'main-content'
                        : 'main-content main-content--expanded'
                }`}
                ref={dropdownRef}
            >
                <div className="filter-wrapper">
                    <Search
                        suggestionsLimit={5}
                        geocoding={true}
                        controlsToggle={controlsToggle}
                        setOpenControl={setOpenedControl}
                        openedControl={openedControl}
                    />
                    <LayersMenu
                        openedControls={openedControl}
                        controlsToggle={controlsToggle}
                        removeAllFilters={removeAllFilters}
                        onToggle={onToggle}
                    />
                    <Calendar
                        map={map}
                        openedControls={openedControl}
                        controlsToggle={controlsToggle}
                        setMaxZoomBackup={setMaxZoomBackup}
                        maxZoomBackup={maxZoomBackup}
                        clearCustomLayers={clearCustomLayers}
                        selectedValues={selectedValues}
                        onToggle={onToggle}
                        removeAllFilters={removeAllFilters}
                    />
                    {mapReady && show3dSwitch && (
                        <div className={'d3-btn'}>
                            <Form className={'d-flex'}>
                                <Form.Label htmlFor="custom-switch">
                                    Voir les gares en 3D
                                </Form.Label>
                                <Form.Check
                                    type="switch"
                                    className="custom-switch"
                                    onClick={toggleVisibility}
                                    checked={visible}
                                    disabled={openedControl !== ''}
                                />
                            </Form>
                        </div>
                    )}
                </div>
                {currentBasemapInfo && (
                    <CoreMap
                        lng={mapCenter.longitude}
                        lat={mapCenter.latitude}
                        zoom={mapZoom}
                        minZoom={minZoom}
                        maxZoom={maxZoom}
                        pitch={mapPitch || 45}
                        minPitch={minPitch || 0}
                        maxPitch={maxPitch || 60}
                        boundingBox={projectConfigs.boundingBox}
                        SecondaryLayerPopUpComponent={SecondaryLayerPopUp}
                        url={currentBasemapInfo && currentBasemapInfo.url}
                        PopUpComponent={PoiPopUp}
                        showPopup={(poi, state) =>
                            poi.type !== 'Gare' && state !== 'hover'
                        }
                        models={projectConfigs.models}
                        openStreetViewModal={(feature) =>
                            openStreetViewModal(feature, {
                                setMedias: streetViewModal.setMedias,
                            })
                        }
                        setMapReady={(isReady) => setMapReady(isReady)}
                        isBboxIncluded={false}
                        expanded={isSidebarOpen}
                    />
                )}
                {/*{mapReady && show3dSwitch && is3DBtnVisible && (*/}
                {/*    <div className="mapbox-control-bottom-left">*/}
                {/*        <ModelToggleButton/>*/}
                {/*    </div>*/}
                {/*)}*/}
                <StreetViewModal
                    open={streetViewModal.open}
                    onClose={() => {
                        streetViewModal.handleClose();
                        setSideBarOpen(true);
                    }}
                    streetView={streetViewModal.medias[0]}
                    isSidebarOpen={isSidebarOpen}
                />
            </div>
        </>
    );
}

Map.propTypes = {
    position: PropTypes.oneOf(['left', 'right', 'full']).isRequired,
    loading: PropTypes.bool,
    setOpenedControl: PropTypes.func,
    projectConfigs: PropTypes.object,
    sidebarRef: PropTypes.object,
    basemaps: PropTypes.array,
    isSidebarOpen: PropTypes.bool,
    openedControl: PropTypes.string,
    setSideBarOpen: PropTypes.func,
    removeAllFilters: PropTypes.func,
    onToggle: PropTypes.func,
    centerOnPoint: PropTypes.func,
    selectedValues: PropTypes.object,
};

Map.defaultProps = {
    position: 'left',
    loading: true,
};

export default Map;
