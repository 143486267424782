import React, { useRef } from 'react';
import { Col, Container, Image, Nav, Row, Tab } from 'react-bootstrap';
import { useParams } from 'react-router';
import { Link, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import searchBtn from '../../../../assets/icons/search-white.svg';
import PropTypes from 'prop-types';
import Search from '../../../../components/Search/Search';
import Line from '../../../../components/LineView/Line';
import Simulator from '../../../../components/SimulatorSearch/Simulator';
import { Layers, SelectedItem } from '@mappr/react-lib';

const Tabs = (props) => {
    const { t } = useTranslation();
    const { lang, mode } = useParams();
    const dropdownRef = useRef();
    const {
        openedControl,
        setOpenedControl,
        selectedValues,
        onToggleValues,
        coreFilters,
        coreFilterLoading,
        initialFilters,
        onToggle,
        handleCalendarClick,
        clearDataLayers,
        controlsToggle,
    } = props;

    return (
        <>
            <Layers group={'basemap'}>
                {/* eslint-disable-next-line no-unused-vars */}
                {(_, { toggleLayer }) => {
                    return (
                        <SelectedItem>
                            {(
                                // eslint-disable-next-line no-empty-pattern
                                {},
                                { deselect }
                            ) => (
                                <Container
                                    fluid
                                    className="tab-content-wrapper"
                                >
                                    <div
                                        className="mobile-filter-wrapper"
                                        ref={dropdownRef}
                                    >
                                        <Row className="flex-container text-center">
                                            <Col className="border-end align-items-center justify-content-center d-flex">
                                                <NavLink
                                                    className={'text-white'}
                                                    onClick={() => {
                                                        controlsToggle(
                                                            'layers'
                                                        );
                                                    }}
                                                    to={{
                                                        pathname:
                                                            '/fr/line/map',
                                                        hash: window.location
                                                            .hash,
                                                    }}
                                                >
                                                    {t('placeOfInterest')}
                                                </NavLink>
                                            </Col>
                                            <Col className="border-end align-items-center justify-content-center d-flex">
                                                <NavLink
                                                    className={'text-white'}
                                                    onClick={() => {
                                                        controlsToggle(
                                                            'calendar'
                                                        );
                                                        setTimeout(() => {
                                                            clearDataLayers(
                                                                toggleLayer
                                                            );
                                                            handleCalendarClick(
                                                                deselect,
                                                                toggleLayer
                                                            );
                                                        }, 1000);
                                                    }}
                                                    to={{
                                                        pathname:
                                                            '/fr/line/map',
                                                        hash: window.location
                                                            .hash,
                                                    }}
                                                >
                                                    {t('calendar.title')}
                                                </NavLink>
                                            </Col>
                                            <Col
                                                className={
                                                    'align-items-center justify-content-center d-flex'
                                                }
                                                onClick={() =>
                                                    setOpenedControl(
                                                        openedControl ===
                                                            'search'
                                                            ? ''
                                                            : 'search'
                                                    )
                                                }
                                            >
                                                <Image
                                                    className="me-1"
                                                    src={searchBtn}
                                                    alt=""
                                                />
                                                {t('mobile.search')}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                {openedControl === 'search' && (
                                                    <Search
                                                        suggestionsLimit={5}
                                                        geocoding={true}
                                                        controlsToggle={
                                                            controlsToggle
                                                        }
                                                        setOpenControl={
                                                            setOpenedControl
                                                        }
                                                    />
                                                )}
                                            </Col>
                                        </Row>
                                    </div>
                                    <Tab.Container
                                        id="left-tabs-example"
                                        defaultActiveKey={
                                            mode === 'line'
                                                ? 'line'
                                                : 'simulator'
                                        }
                                    >
                                        <main className="h-100">
                                            <Nav
                                                variant="pills"
                                                className="menu-tab"
                                            >
                                                <Col>
                                                    <Link
                                                        to={`/${lang}/line/list/${window.location.hash}`}
                                                    >
                                                        <Nav.Link
                                                            eventKey="line"
                                                            as="button"
                                                            className="btn btn-primary w-75"
                                                        >
                                                            {t('lines.title')}
                                                        </Nav.Link>
                                                    </Link>
                                                </Col>
                                                <Col>
                                                    <Nav.Item>
                                                        <Link
                                                            to={`/${lang}/simulator/list/${window.location.hash}`}
                                                        >
                                                            <Nav.Link
                                                                eventKey="simulator"
                                                                as="button"
                                                                className="btn btn-primary w-100"
                                                            >
                                                                {t(
                                                                    'simulator.tab'
                                                                )}
                                                            </Nav.Link>
                                                        </Link>
                                                    </Nav.Item>
                                                </Col>
                                            </Nav>
                                            <Tab.Content>
                                                <Line
                                                    selectedValues={
                                                        selectedValues
                                                    }
                                                    onToggleValues={
                                                        onToggleValues
                                                    }
                                                    filters={coreFilters}
                                                    loading={coreFilterLoading}
                                                    initialFilters={
                                                        initialFilters
                                                    }
                                                    onToggle={onToggle}
                                                />
                                                <Simulator />
                                            </Tab.Content>
                                        </main>
                                    </Tab.Container>
                                </Container>
                            )}
                        </SelectedItem>
                    );
                }}
            </Layers>
        </>
    );
};
Tabs.propTypes = {
    openedControl: PropTypes.string,
    setOpenedControl: PropTypes.func,
    clearCustomLayers: PropTypes.func,
    loading: PropTypes.bool,
    project: PropTypes.object,
    selectedValues: PropTypes.object,
    onToggle: PropTypes.func,
    onToggleValues: PropTypes.func,
    coreFilters: PropTypes.array,
    coreFilterLoading: PropTypes.bool,
    initialFilters: PropTypes.array,
    handleCalendarClick: PropTypes.array,
    clearDataLayers: PropTypes.array,
    controlsToggle: PropTypes.array,
};
export default Tabs;
