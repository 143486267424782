import React, { useCallback, useContext } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import ListLoader from '../../../../components/loaders/ListLoader/ListLoader';
import { Journey } from '../../../../components/Journey/Journey';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../../../context';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import NotFound from '../../../../components/NotFound/NotFound';
import { useJourneyHash, useJourneys } from '../../../../hooks/journeys.ts';

export const JourneyList = ({ project }) => {
    const { t } = useTranslation();
    const { setAppState } = useContext(AppContext);
    const { page } = useParams();
    const history = useHistory();

    const { data: journeys, isPending, isError } = useJourneys();
    const { clear: clearJourneyHash } = useJourneyHash();

    const goToMap = useCallback(() => {
        if (page) {
            setAppState({ prop: 'mobilePage', value: 'map' });
            history.push({
                pathname: '/fr/line/map/',
                hash: window.location.hash,
            });
        }
    }, [history, page, setAppState]);

    return (
        <main>
            <Container className="sidebar-page back-btn pt-0">
                {isPending ? (
                    <ListLoader />
                ) : isError ? (
                    <NotFound />
                ) : (
                    <>
                        <Row className="flex-row align-items-center back-btn cursor-pointer">
                            <Col onClick={clearJourneyHash}>
                                <i
                                    style={{
                                        borderColor: project.secondaryColor,
                                    }}
                                    className="arrow left"
                                />
                                <span
                                    style={{
                                        color: project.secondaryColor,
                                    }}
                                    className="ps-2"
                                >
                                    {t('back')}
                                </span>
                            </Col>
                        </Row>
                        <Row>
                            <Journey journeys={journeys} />
                        </Row>
                        <Container>
                            <Row>
                                <Col className={'ps-0 pe-0'}>
                                    <Button
                                        className="nav-link w-100 cursor-pointer mb-3 btn-calculate active"
                                        onClick={goToMap}
                                    >
                                        {t('goMap')}
                                    </Button>
                                </Col>
                            </Row>
                        </Container>
                    </>
                )}
            </Container>
        </main>
    );
};
JourneyList.propTypes = {
    project: PropTypes.object,
};
