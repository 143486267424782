import React from 'react';
import { useHistory } from 'react-router';
import Header from '../../Header/Header';
import { Col, Row } from 'react-bootstrap';
import './Calendar.scss';
import PropTypes from 'prop-types';

const Calendar = ({ project }) => {
    let history = useHistory();
    const goToPreviousPath = () => {
        history.goBack();
    };
    return (
        <>
            <Header
                projectConfigs={project.configs}
                deselect={goToPreviousPath}
            />
            <main>
                <div className="sidebar-page text-white">
                    <Row className="mb-3">
                        <Col>
                            <div className="sidebar-page text-white calendar-sidebar">
                                <p className="title mt-0">
                                    Découvrez les mises en service
                                </p>
                                <div className="description">
                                    Le nouveau métro sera progressivement mis en
                                    service entre 2024 et 2031.
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </main>
        </>
    );
};

Calendar.propTypes = {
    project: PropTypes.object,
};

export default Calendar;
