import React from 'react';
import './Calendar.scss';
import { Layers, SelectedItem } from '@mappr/react-lib';
import PropTypes from 'prop-types';
import { CalendarItems } from '../../../../components/Calendar/CalendarItems';

export default function Calendar({
    openedControls,
    controlsToggle,
    selectedValues,
    onToggle,
    handleCalendarClick,
}) {
    return (
        <Layers group={'basemap'}>
            {({ layers, activeLayers }, { toggleLayer }) => (
                <SelectedItem>
                    {(
                        // eslint-disable-next-line no-empty-pattern
                        {},
                        { deselect }
                    ) => (
                        <CalendarItems
                            openedControls={openedControls}
                            controlsToggle={controlsToggle}
                            toggleLayer={toggleLayer}
                            layers={layers}
                            activeLayers={activeLayers}
                            onToggle={onToggle}
                            selectedValues={selectedValues}
                            closeCalendar={() =>
                                handleCalendarClick(deselect, toggleLayer)
                            }
                        />
                    )}
                </SelectedItem>
            )}
        </Layers>
    );
}
Calendar.propTypes = {
    openedControls: PropTypes.string,
    controlsToggle: PropTypes.func,
    onToggle: PropTypes.func,
    handleCalendarClick: PropTypes.func,
    selectedValues: PropTypes.object,
};
